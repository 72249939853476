import React, {useState} from 'react';
// import {FaChevronCircleLeft} from "react-icons/fa"
// import {Link} from "react-router-dom";
import CardPreview from "./CardPreview";
import PendingForm from "./PendingForm";
import SuperBloomLogo from "./../img/superbloom.png"
import Bloom from "./../img/bloom.png"

// const uploadImage = async (file, path) => {
//     const storageRef = ref(storage, path)
//     const uploadTask = await uploadBytesResumable(storageRef, file);
//     return await getDownloadURL(uploadTask.ref)
// }

// Translation Higher Order Component
// import {
//     setTranslations,
//     setDefaultLanguage,
//     setLanguageCookie,
//     setLanguage,
//     translate,
// } from 'react-switch-lang';
// import en from 'en.json';
// import th from 'th.json';

const PendingEditor = () => {
    const [card, setCard] = useState([])
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [farbAuswahl, setFarbauswahl] = useState(0)

    return (
        <div>
            <header>
                {/*<Link to="/"*/}
                {/*      key={3}*/}
                {/*>*/}
                {/*    <FaChevronCircleLeft style={{color: "#fffffe"}} size={38}/>*/}
                {/*</Link>*/}
                <img alt="Superbloom" src={SuperBloomLogo} width={300}/>
                <img src={Bloom} width={200} className="bloom" id="left-bloom" alt={"Left flower"}/>
                <img src={Bloom} width={200} className="bloom" id="right-bloom" alt={"Right flower"}/>
            </header>
            <div className="newWorker">
                <div className="editor_container">
                    <p className="lineText">Gestaltungsoptionen</p>
                    <PendingForm setCard={setCard}
                                 name={name}
                                 setName={setName}
                                 description={description}
                                 setDescription={setDescription}
                                 farbAuswahl={farbAuswahl}
                                 setFarbauswahl={setFarbauswahl}
                    />
                </div>
                <div className="editor_preview">
                    <p className="lineText">Vorschau</p>
                    <CardPreview card={card}/>
                </div>
            </div>
        </div>
    );
};

export default PendingEditor;