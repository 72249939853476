import React, {useState} from 'react';
import InputText from "./InputText";
import Popup from "reactjs-popup";

const PopUpPrint = ({submit, error}) => {
    const [phone, setPhone] = useState("")

    return (
        <Popup trigger={<button className="btn">Drucken</button>} modal>
            {close => (
                <form className="popup" onSubmit={(e) => {
                    submit(e, phone, close)
                    setPhone("")
                }}>
                    <div>
                        <h1>Fast fertig!</h1>
                        <p style={{fontSize: "16px"}}>
                            Nachdem Ihre Daten verarbeitet wurden, können Sie zum Checkout fortfahren.
                        </p>
                    </div>
                    <InputText placeholder="+49 123 4567890"
                               value={phone}
                               onChange={(e) => setPhone(e.target.value)}
                               label="Telefonnummer"
                               error={error}
                               type={"tel"}
                    />
                    <div style={{textAlign: "center"}}>
                        <button className="btn whiteButton"
                                onClick={close}
                                type="button"
                        >
                            Abbrechen
                        </button>
                        <input type="submit" className="btn" value="Abschicken"/>
                    </div>

                </form>
            )}
        </Popup>
    );
};

export default PopUpPrint;