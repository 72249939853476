import {createCanvas, loadImage} from 'canvas'
import QRCode from "qrcode"
// import front02 from "../img/The-Pending-card-design-01.png"
// import back02 from "../img/The-Pending-card-design-01-back.png"
// import front01 from "../img/The-Pending-card-design-02.png"
// import back01 from "../img/The-Pending-card-design-02-back.png"
import front from "../img/superbloom_front.png"
import back from "../img/superbloom_back.png"

// export const pendingCreation = async (name, description, color) => {
//     return [
//         await frontCard(name, description, color),
//         await backCard(color)
//     ]
// }

export const superbloom = async (name) => {
    return [
        front,
        await superbloomBack(name)
    ]
}

const superbloomBack = async (n) => {
    const color = "#242655"

    const canvas = createCanvas(4044, 2550)
    let ctx = canvas.getContext("2d")

    ctx.drawImage(await loadImage(back), 0, 0, 4044, 2550)
    const qr = await generateQRCode(890, color)
    ctx.drawImage(await loadImage(qr[0]), 2855, 1371, 890, 890);

    ctx.fillStyle = "#ffffff"
    ctx.strokeStyle = color

    const name = n === "" ? "Max Mustermann" : n

    ctx.lineJoin="round";
    ctx.miterLimit = 0
    ctx.font = "170px 'Platform', sans-serif"
    ctx.lineWidth = 68
    adjustFontSize(name.toUpperCase(), ctx, 2250, 170, "px 'Platform', sans-serif")
    ctx.strokeText(name.toUpperCase(), 177, 2225)
    ctx.lineWidth = 80
    ctx.strokeText(name.toUpperCase(), 177, 2225)
    ctx.fillText(name.toUpperCase(), 177, 2225)

    return canvas.toDataURL("image/png")
}

const adjustFontSize = (str, ctx, width, current, font) => {
    let size = ctx.measureText(str).width;
    while (size > width) {
        current -= 1
        ctx.font = current + font
        size = ctx.measureText(str).width
    }
}

// const frontCard = async (name, description, color) => {
//     if (!name) {
//         name = "Max Mustermann"
//         if (!description) description = "Fotograf"
//     }
//     const canvas = createCanvas(4044, 2546)
//     let ctx = canvas.getContext('2d')
//     ctx.globalAlpha = 1;
//     ctx.fillStyle = "#fffffe"
//
//
//     ctx.drawImage(await loadImage(color === 0 ? front01 : front02), 0, 0, 4044, 2546)
//     ctx.textAlign = "right"
//     ctx.font = "600 226px 'Poppins', sans-serif"
//     adjustColorfulFontSize(name, ctx, 3500, 226, "px 'Poppins', sans-serif")
//     ctx.fillText(name, 3766, 2112);
//     ctx.font = "130px 'Poppins', sans-serif"
//     ctx.fillText(description, 3766, 2307);
//
//     return canvas.toDataURL("image/png")
// }
//
// const backCard = async (color) => {
//     const canvas = createCanvas(4044, 2546)
//     let ctx = canvas.getContext('2d')
//     ctx.globalAlpha = 1;
//     ctx.drawImage(await loadImage(color === 0 ? back01 : back02), 0, 0, 4044, 2546)
//
//     ctx.drawImage(await loadImage(await generateQRCode(1019 - 132)), 736, 764 + 132, 1019 - 132 * 2, 1019 - 132 * 2)
//     return canvas.toDataURL("image/png")
// }

const generateQRCode = async (size, colour) => {
    let website = 'bumpee.co/' + makeId(12);

    return [await QRCode.toDataURL(website, {
        errorCorrectionLevel: 'M',
        margin: 0,
        width: size,
        color: {
            dark: colour,
            light: "#ffffff"
        }
    }), website];
}

const makeId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

const adjustColorfulFontSize = (str, ctx, width, current, font) => {
    let size = ctx.measureText(str).width;
    const desired_width = width;
    let nameSize = current;

    while (size > desired_width) {
        nameSize -= 1
        ctx.font = "600 " + nameSize + font
        size = ctx.measureText(str).width
    }
}