import React, {useEffect, useState} from 'react';
import InputText from "./InputText";
import {superbloom} from "../Functions/PendingCreation";
import PopUpPrint from "./PopUpPrint";
import {addDoc, collection} from "firebase/firestore";
import {db} from "../Functions/firebase";

const PendingForm = ({setCard, name, setName}) => {
    const [timer, setTimer] = useState()
    const [errors, setErrors] = useState([])


    useEffect(() => {
        clearTimeout(timer);
        setTimer(setTimeout(async () => {
            updateCard()
        }, 500))
    }, [name/*, description*/])

    // useEffect(() => {
    //     updateCard()
    // }, [farbAuswahl])

    const submit = async (e, phone, close) => {
        e.preventDefault()
        let fehler = {}

        //Telefon
        if (!phone) {
            fehler["phone"] = "Bitte ausfüllen";
        }
        // const image1URL = await uploadImage(image1, `/pendingCards/${image_id}front`)
        // const image2URL = await uploadImage(image2, `/pendingCards/${image_id}back`)

        if (Object.keys(fehler).length === 0) {
            uploadFirestore(phone, name).then(() => {
                console.log("success")
                setName("")
                setErrors([])
                close()
            })
        } else {
            setErrors(fehler)
        }
    }

    const uploadFirestore = async (phone, name) => {
        try {
            const docRef = await addDoc(collection(db, "orders"), {
                full_name: name,
                phone: phone
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    const updateCard = async () => {
        setCard(await superbloom(name))
    }

    return (
        <div className='add-form'>
            <InputText placeholder="Max Mustermann" label="Name" value={name} onChange={
                (e) => setName(e.target.value)
            }
                       type={"text"}
            />
            {/*<InputText placeholder="Fotograf" label="Beschreibung" value={description} onChange={*/}
            {/*    (e) => setDescription(e.target.value)*/}
            {/*}*/}
            {/*           type={"text"}*/}
            {/*/>*/}
            {/*<ColorSelector farbAuswahl={farbAuswahl} setFarbauswahl={setFarbauswahl}/>*/}
            <PopUpPrint submit={submit} error={errors["phone"]}/>
        </div>
    );
};

export default PendingForm;