import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Options from "./Components/Options";
import PendingEditor from "./Components/PendingEditor";

function App() {
    return (
        <PendingEditor />
        // <BrowserRouter>
        //     <Routes>
        //         <Route path="/"
        //                element={<Options/>}/>
        //         <Route path="/pending"
        //                element={<PendingEditor />}/>
        //     </Routes>
        // </BrowserRouter>

    );
}

export default App;
