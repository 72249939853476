const CardPreview = ({card}) => {


    return (
        <>
            {
                card[0] &&
                <>
                        <img key={0} src={card[0]} alt="cardFront" className="cardPreview"/>
                        <img key={1} src={card[1]} alt="cardBack" className="cardPreview"/>
                </>
            }
        </>
    );
};

export default CardPreview;
