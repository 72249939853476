import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {getFirestore} from "firebase/firestore";

const app = initializeApp ({
    apiKey: "AIzaSyBRB_TKVKrGrbdnK8wlZUGYC8sX4musZJs",
    authDomain: "print-events.firebaseapp.com",
    projectId: "print-events",
    storageBucket: "print-events.appspot.com",
    messagingSenderId: "282805952666",
    appId: "1:282805952666:web:b2e228c0ba2b6e344dff23"
})

export const storage = getStorage(app);
export const db = getFirestore(app);