const InputText = ({ label, value, placeholder, onChange, error, type}) => {
    return (
        <div className="form-control">
            <label>{label}</label>
            <input type={type}
                   placeholder={placeholder}
                   value={value}
                   onChange={onChange}
            />
            <span style={{ color: "red" }}>{error}</span>
        </div>
    );
};

export default InputText;
